<template>
  <div>
    <div class="m-2">
      <!-- Table Top -->
      <b-container>
        <b-row>
          <b-col cols="2"></b-col>
          <b-col cols="8">
            <h1>Add Branch</h1>
          </b-col>
          <b-col cols="2"></b-col>
        </b-row>
        <b-row>
          <b-col cols="2"></b-col>
          <b-col md="8">
            <b-card>
              <b-row align-h="center">
                <!-- <b-col cols="4"></b-col> -->
                <b-col cols="*">
                  <b-form-group
                    class="text-center"
                    ref="picture"
                    :state="nameState"
                    label=" "
                    label-for="name-input"
                    invalid-feedback="Picture is required."
                  >
                    <b-media no-body ref="picture">
                      <div v-if="logoloading == true">
                        <b-spinner
                          variant="primary"
                          type="grow"
                          class="m-5"
                          label="Spinning"
                        ></b-spinner>
                      </div>

                      <b-media-aside>
                        <b-link>
                          <b-img
                            ref="previewEl"
                            rounded
                            src="https://cdn.cloudious.net/file-1678110140675-811622963.png"
                            @click="$refs.fileProfile.click()"
                            v-if="logoloading == false"
                            thumbnail
                            fluid
                            height="120px"
                            width="120px"
                          />

                          <!-- <img v-if= "loading==false" rounded :src="require('@/assets/images/elements/Unicorn-Cake.jpg')" @click="$refs.files.click()" style="width: 7vw; height: 7vw; padding-left:20px; padding-top:20px;" alt="imgAdd"/>  -->
                        </b-link>

                        <b-link v-if="logoloading == 'loaded'">
                          <b-button
                            class="position-absolute mt-3 ml-3 rounded-circle btn-icon"
                            variant="gradient-danger"
                            @click="deleteLogo()"
                          >
                            <feather-icon icon="Trash2Icon" />
                          </b-button>

                          <b-img
                            rounded
                            :src="plus"
                            height="120px"
                            width="120px"
                          />
                          <br />
                        </b-link>
                        <input
                          type="file"
                          id="fileProfile"
                          hidden
                          ref="fileProfile"
                          @change="selectlogo()"
                          accept="image/*"
                        />
                      </b-media-aside>
                    </b-media>
                    <small class="mr-1 text-danger" v-if="logoerror"
                      >Logo is required.</small
                    >
                  </b-form-group>
                </b-col>
                <!-- <b-col cols="4"></b-col>
        -->
              </b-row>

              <b-form @submit.prevent>
                <b-row class="mt-1">
                  <b-col md="6">
                    <b-form-group
                      label="School name *"
                      invalid-feedback="School name is required."
                    >
                      <b-form-input
                        id="mc-first-name"
                        placeholder="My Skool"
                        ref="name"
                        v-model="myObj.name"
                        @focusout="CheckName()"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Branch name *"
                      invalid-feedback="Branch name is required."
                    >
                      <b-form-input
                        id="mc-first-name"
                        placeholder="DHA Toheed"
                        ref="branch"
                        v-model="myObj.branch"
                        @focusout="CheckBranch()"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group
                      label="Phone number *"
                      invalid-feedback="Please enter correct phone number."
                    >
                      <vue-tel-input v-model="myObj.phone"></vue-tel-input>
                      <!-- @input="CheckPhone" -->
                      <small class="mr-1 text-danger" v-if="phoneerror"
                        >Phone number is required.</small
                      >
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Mobile number *"
                      invalid-feedback="Please enter correct mobile."
                    >
                      <!-- <b-input-group>
                        <b-input-group-prepend is-text>
                          PAK (+)
                        </b-input-group-prepend>

                        <the-mask
                          type="tel"
                          :mask="['## ### #######']"
                          id="phone"
                          v-model="myObj.mobile"
                          class="form-control"
                          ref="mobile"
                          @focusout="CheckMobile()"
                          placeholder="92 333 9876543"
                        ></the-mask>
                      </b-input-group> -->
                      <vue-tel-input v-model="myObj.mobile"></vue-tel-input>
                      <small class="mr-1 text-danger" v-if="mobileerror"
                        >Mobile number is required.</small
                      >

                      <!-- <b-form-input
            id="mc-country"
            placeholder="03xx-xxxxxxx"
            ref="mobile"
            v-model="myObj.mobile"
            @focusout="CheckMobile()"

          />-->
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Email *"
                      invalid-feedback="Email address is required."
                    >
                      <div class="form-label-group">
                        <b-form-input
                          ref="email"
                          type="email"
                          id="mc-email"
                          placeholder="myskool@example.com"
                          v-model="myObj.email"
                          @focusout="CheckEmail()"
                        />
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Website (optional)"
                      invalid-feedback="Wesite is required."
                    >
                      <div class="form-label-group">
                        <b-form-input
                          id="mc-compamy"
                          ref="website"
                          placeholder="www.myskool.com"
                          v-model="myObj.website"
                          @focusout="CheckWebsite()"
                        />
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Address *"
                      invalid-feedback="Address is required."
                    >
                      <b-form-input
                        id="mc-last-name"
                        placeholder="Address"
                        v-model="myObj.address"
                        ref="address"
                        @focusout="CheckAddress()"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="12" class="ml-1 text-right">
                    <b-button
                      class="mr-1"
                      @click="BacktoGrid()"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                    >
                      <feather-icon icon="ChevronLeftIcon" />
                      <span class="align-middle"> Back</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="success"
                      class="mr-1"
                      @click="Add()"
                      :disabled="request"
                    >
                      <b-spinner
                        v-if="request"
                        small
                        variant="light"
                        label="Spinning"
                      ></b-spinner>
                      <span v-else>Save</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card>
          </b-col>
          <b-col cols="2"></b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BContainer,
  BMediaAside,
  BAside,
  BSpinner,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { TheMask } from "vue-the-mask";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,
    TheMask,
    BInputGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BFormGroup,
    BInputGroupPrepend,
    // BFormCheckbox,
    BForm,
    BContainer,
    BMediaAside,
    BImg,
    BMedia,
    //BAvatar,
    BLink,
    BSpinner,
    //BBadge,
    // BDropdown,
    //BDropdownItem,
    //BPagination,
    //vSelect,
    //flatPickr,
    //'gallery': VueGallery
  },

  props: ["row"],
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  created() {
    this.myrow = this.$route.params.row;
    // console.log(this.$route.params.row);
    this.LoadForm();
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    PageChange() {
      console.log(this.currentPage);
      return this.LoadData();
    },
    filterFlavours: function () {
      return this.items.filter((pro) => {
        return pro.Name.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      myrow: "",
      logoerror: false,
      phoneerror: false,
      mobileerror: false,

      plus: "",
      logoloading: "",
      request: false,
      searchQuery: "",
      currentPage: 1,
      totalRows: 0,

      errors: {
        status: false,
      },
      statusOptions: ["In stock", "Out stock"],
      filterData: "",
      date: {
        range: "",
      },
      index: null,
      cover: "",
      docs: [],
      partners: [],
      fields: [
        "#",
        { label: "Flavour", key: "Name" },
        { label: "Status", key: "Status" },
        "actions",
      ],
      items: [],
      selected: null,

      rangeDate: null,
      myObj: {
        id: 0,
        name: "",
        branch: "",
        address: "",
        phone: "",
        mobile: "",
        email: "",
        website: "",
        logo: "",
      },
      detailObj: {},
      limit: this.$store.state.branches[0].limit,
      // limit: 0,
    };
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    deleteLogo() {
      this.myObj.logo = "";
      this.logoloading = false;
    },
    selectlogo() {
      var axios = require("axios");

      this.fileProfile = this.$refs.fileProfile.files[0];
      //console.log(this.fileProfile.name);

      //console.log(this.fileProfile, this.loading );
      //Upload cover
      if (this.fileProfile !== "") {
        this.logoloading = true;

        let formData = new FormData();
        formData.append("file", this.fileProfile);
        //console.log(this.fileProfile);
        axios
          .post("https://upload.appick.io/profile-picture", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            // var fn1 = e.data.myresp[0].path;
            var fn1 = e.data.file.path;
            // console.log(fn);
            this.plus = fn1;
            this.myObj.logo = this.plus;
            //
            // console.log(this.plus);
            //console.log(this.myObj.coverImg);
            this.logoloading = "loaded";
            this.logoerror = false;
          });
      }
    },
    CheckLogo() {
      var elem = this.$refs["picture"];
      if (this.myObj.logo == "") {
        return (this.logoerror = true);
      } else {
        return (this.logoerror = false);
      }
    },
    CheckWebsite() {
      var elem = this.$refs["website"];
      //elem.state = false;
      var re =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
      if (this.myObj.website === "") {
        return (elem.state = undefined);
      } else {
        return (elem.state = true);
        // if (re.test(this.myObj.website)) {
        //   return (elem.state = true);
        // } else {
        //   return (elem.state = false);
        // }
      }
    },
    CheckEmail() {
      var elem = this.$refs["email"];
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (
        re.test(String(this.myObj.email).toLowerCase()) &&
        this.myObj.email !== ""
      ) {
        return (elem.state = true);
      } else {
        return (elem.state = false);
      }
    },
    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.name < 3 || this.myObj.name == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckAddress() {
      var elem = this.$refs["address"];
      if (this.myObj.address < 3 || this.myObj.address == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckBranch() {
      var elem = this.$refs["branch"];
      if (this.myObj.branch < 3 || this.myObj.branch == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckPhone(s, e) {
      // console.log(e);
      // if (this.myObj.phone) {
      //   if (e.valid) {
      //     this.phoneerror = false;
      //     this.myObj.phone = e.number;
      //     // console.log(this.myObj.phone);
      //   } else {
      //     this.phoneerror = true;
      //   }
      // } else {
      //   this.phoneerror = false;
      // }
      if (this.myObj.phone !== "" && this.myObj.phone.startsWith("03")) {
        if (this.myObj.phone.length == 12) {
          return (this.phoneerror = false);
        } else {
          return (this.phoneerror = true);
        }
      }
      return false;
    },
    CheckMobile() {
      if (this.myObj.mobile !== "") {
        if (this.myObj.mobile.length == 12) {
          return (this.mobileerror = false);
        } else {
          return (this.mobileerror = true);
        }
      }
      return false;
      // var elem = this.$refs['mobile'];
      // var re = /^(\+92|0|92)[0-9]{10}$/;
      // if (re.test(this.myObj.mobile) && this.myObj.mobile !== '') {
      //   return (this.mobileerror = false);
      // } else {
      //   return (this.mobileerror = true);
      // }
    },
    BacktoGrid() {
      this.$router.push("/manage-branches");
    },
    checkTitle() {
      var elem = this.$refs["title"];
      return (elem.state = this.myObj.Name.length > 2 ? true : false);
    },
    checkStatus() {
      if (this.myObj.Status == "") {
        return (this.errors.status = true);
      } else {
        return (this.errors.status = false);
      }
    },
    OpenAddModal() {
      this.$bvModal.show("modal-login");
      this.myObj.flavourId = 0;
      this.myObj.vendorId = this.$store.state.userData.userID;
      this.myObj.Name = "";
      this.myObj.Status = "";
    },
    OpenEditModal(row) {
      this.$bvModal.show("modal-login");
      this.myObj.flavourId = row.flavourId;
      this.myObj.vendorId = row.vendorId;
      this.myObj.Name = row.Name;
      this.myObj.Status = row.Status;
    },

    async Add() {
      if (
        this.myObj.id == 0 &&
        this.limit == this.$store.state.branches.length
      ) {
        this.$bvToast.toast(
          "You have reached your limit.Please Contact MySkool Team.",
          {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          }
        );
      } else {
        // console.log("req:", this.request);
        // this.CheckLogo();
        this.CheckWebsite();
        // this.CheckEmail();
        this.CheckName();
        this.CheckAddress();
        this.CheckBranch();
        this.CheckPhone();
        this.CheckMobile();

        if (
          // this.CheckLogo() == true ||
          this.CheckWebsite() == false ||
          // this.CheckEmail() == false ||
          this.CheckName() == false ||
          this.CheckAddress() == false ||
          this.CheckBranch() == false ||
          this.CheckPhone() == true ||
          this.CheckMobile() == true
        ) {
          this.$bvToast.toast("Please fill the form correctly.", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        } else {
          this.request = true;
          // console.log(this.myObj);
          if (this.myObj.id == 0) {
            var status = await this.post({
              url:
                this.$store.state.domain +
                "campuses?db=" +
                this.$store.state.userData.db +
                "&cId=" +
                this.$store.state.userData.cId,
              body: this.myObj,
              message: "Campus added successfully.",
              token: this.$store.state.userData.token,
              context: this,
            });
            this.request = false;
            //this.visibility1 = false;
            if (status) {
              this.$router.push("/manage-branches");
            }
          } else {
            //Edit
            var status = await this.put({
              url:
                this.$store.state.domain +
                "campuses/" +
                this.myObj.id +
                "?db=" +
                this.$store.state.userData.db +
                "&cId=" +
                this.$store.state.userData.cId,
              body: this.myObj,
              message: "Campus updated successfully.",
              token: this.$store.state.userData.token,
              context: this,
            });
            this.request = false;
            if (status) {
              this.$router.push("/manage-branches");
            }
          }
        }
      }
    },

    LoadForm() {
      //console.log(this.myrow ,"This.lOad Form");
      if (this.myrow == undefined) {
        return 0;
      } else {
        this.myObj = this.myrow.row;
        // console.log(this.myObj);
        if (this.myObj.logo) {
          this.plus = this.myObj.logo;
          this.logoloading = "loaded";
        } else {
          this.logoloading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>
